import React, { useEffect, useState } from 'react'
// React Router
import { useNavigate } from 'react-router-dom'
// i18
import { useTranslation } from 'react-i18next'
// Ant Design
import { Col, Row, Image, Flex, Input, Checkbox, Button, Typography, message } from 'antd'
// Icons
import { UserOutlined, LockOutlined } from '@ant-design/icons'
// CriptoJs
import CryptoJS from 'crypto-js'
// JsCookie
import Cookies from 'js-cookie'
// Axios
import axios from 'axios'
// Config
import {API_URL} from '../../config'

export const Login = () => {

    function FormLogin() {
        const { Title } = Typography
        const navigate = useNavigate()
        const { t } = useTranslation(["translation"]);

        // Api login
        const [user, setUser] = useState("");
        const [password, setPassword] = useState("");
        const [loading, setLoading] = useState(false);
        const [check, setCheck] = useState(false);

        const login = async () => {
            setLoading(true)
            try {
                const res = await axios.post(API_URL + "/auth/login", {
                    email: user,
                    password: password
                })
                if(res.status === 201) {
                    navigate("/list")
                    setLoading(false)

                    localStorage.setItem("admin", res.data.is_admin)
                    
                    const encryPassCookie = CryptoJS.AES.encrypt(password, "@murphyCookie").toString();
                    Cookies.set("user", user, { expires: 1 })
                    Cookies.set("pass", encryPassCookie, { expires: 1 })

                    if(check) {
                        const encryPass = CryptoJS.AES.encrypt(password, "@murphy").toString();
                        localStorage.setItem("user", user);
                        localStorage.setItem("pass", encryPass);
                    }
                    else {
                        localStorage.removeItem("user");
                        localStorage.removeItem("pass");
                    }
                }
            } 
            catch (error) {
                setLoading(false)
                if(error.response.data.code===1068) {
                    errorApi(t("api_login_error"))
                }
                else {
                    errorApi(t("error_login"))
                }
            }
        }

        // Para checar si el recuerdame esta activo
        useEffect(() => {
            const user = localStorage.getItem("user");
            const pass = localStorage.getItem("pass");

            if(user !== null) {
                const bytes  = CryptoJS.AES.decrypt(pass, "@murphy");
                const originalPass = bytes.toString(CryptoJS.enc.Utf8);

                setUser(user);
                setPassword(originalPass);
                setCheck(true);
            }
        }, [])

        // Mensajes de error
        const [messageApi, contextHolder] = message.useMessage();
        const errorApi = (men) => {
            messageApi.open({
                type: 'error',
                content: men,
            });
        };

        return (
            <>
                <div style={{position: "absolute", width: ".1px", height: "100%", left: "0", display: "flex", alignItems: "center"}}>
                    <div style={{width: "100%", height: "70%", backgroundColor: "#fff"}}></div>
                </div>

                <Flex align="center" vertical style={{ width: "70%"}}>
                    <Title level={3} style={{color: "#fff", fontWeight: "400", textAlign: "center", marginBottom: "15px"}}>{t("title_login")}</Title>

                    <Input 
                        value={user}
                        size="large" 
                        placeholder={t("input_user")} 
                        prefix={<UserOutlined />} 
                        style={{marginBottom: "15px"}}
                        onChange={(e) => setUser(e.target.value)}
                    />
                    <Input.Password 
                        value={password}
                        size="large" 
                        placeholder={t("input_password")} 
                        prefix={<LockOutlined />} 
                        style={{marginBottom: "15px"}} 
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    
                    <Checkbox onChange={(e) => setCheck(e.target.checked)} checked={check} style={{marginBottom: "10px", alignSelf: "flex-start", color: "#fff"}}>{t("check_login")}</Checkbox>
                    
                    <Button onClick={login} loading={loading} type="ghost" style={{border: "1px solid #F1D030", color: "#F1D030", width: "150px"}}>{t("btn_login")}</Button>
                    
                    {contextHolder}
                </Flex>
            </>
        )
    }


    return (
        <Row style={{height: "100vh", backgroundColor: "#0F0E24"}}>
            <Col span={12}>
                <Flex align="center" justify="center" style={{ height: "100%"}}>
                    <Image width={350} src="https://www.uvp.mx/assets/img/logo_uvp.png" />
                </Flex>
            </Col>

            <Col span={12}>
                <Flex align="center" justify="center" style={{position: "relative", height: "100%"}}>
                    <FormLogin />
                </Flex>
            </Col>
        </Row>
    )
}