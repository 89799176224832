import React, { useEffect, useState } from 'react'
// Layout
import { Session } from '../../layout/Session'
// React Router
import { useNavigate } from 'react-router-dom'
// Ant Design
import { Flex, Button, Table, Tooltip, Typography, Select, Popconfirm, message } from 'antd'
// Icons
import { DeleteOutlined, PlusOutlined, SendOutlined, UnorderedListOutlined } from '@ant-design/icons'
// Moment js
import moment from 'moment'
// CriptoJs
import CryptoJS from 'crypto-js'
// Cookie Js
import Cookies from 'js-cookie'
// Axios
import axios from 'axios'
// Config
import {API_URL} from '../../../config'

export const Tabla = ({t, setSender, templates, loading, reset, setReset, total, page, setPage, pageSize, setPageSize, type, setType}) => {

    const { Text } = Typography;
    const navigate = useNavigate()
    const [isSession, setIsSession] = useState(true);

    const columns = [
        {
            title: <p style={{fontWeight: "700", fontSize: "15px"}}>{t("table_name")}</p>,
            dataIndex: 'nombre',
            width: 450
        },
        {
            title: <p style={{fontWeight: "700", fontSize: "15px"}}>{t("table_lenguage")}</p>,
            dataIndex: 'idioma',
            width: 200
        },
        {
            title: <p style={{fontWeight: "700", fontSize: "15px"}}>{t("table_createdAt")}</p>,
            dataIndex: 'creado',
            width: 240
        },
        {
            title: '',
            dataIndex: 'enviar',
            width: 60
        },
        {
            title: '',
            dataIndex: 'eliminar',
            width: localStorage.getItem("admin") === "true" ? 60 : 1
        },
    ];



    // Para data de tabla
    const [data, setData] = useState([]);
    useEffect(() => {
        const arr = [];
        templates.map((item) => (
            arr.push({
                key: item._id,
                nombre: (
                    <Flex vertical>
                        <Text strong>{item.name}</Text>
                        <Text type="secondary">{item.id_template}</Text>
                    </Flex>
                ),
                idioma: item.language,
                creado: formatDate(item.createdAt),
                enviar: (
                    <Tooltip title={t("btn_send")} color="green">
                        <Button onClick={() => redirectSend(item)} type="ghost" shape="circle" style={{color: "green", border: "1px solid green"}} icon={<SendOutlined />} />
                    </Tooltip>
                ),
                eliminar: (
                    localStorage.getItem("admin") === "true" && 
                        <Tooltip title={t("btn_delete")} color="#FF0000">
                            <Popconfirm
                                title={t("delete_template")}
                                description={t("answer_template")}
                                onConfirm={() => deleteTemplate(item._id)}
                                okText="Si"
                                cancelText="No"
                                zIndex={999999}
                            >
                                <Button type="ghost" shape="circle" style={{color: "#FF0000", border: "1px solid #FF0000"}} icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Tooltip>
                )
            })
        ))
        setData(arr);
    }, [templates])



    // Al dar click en enviar
    const redirectSend = (item) => {
        navigate("/list/send")
        localStorage.setItem("template", item.id_template)
        localStorage.setItem("message", item.message)
    }



    // Eliminar usuario
    const deleteTemplate = async (id) => {
        const user = Cookies.get("user");
        const pass = Cookies.get("pass");
        
        if(!user || !pass) {
            setIsSession(false);
            return;
        }
        else {
            const bytes  = CryptoJS.AES.decrypt(pass, "@murphyCookie");
            const originalPass = bytes.toString(CryptoJS.enc.Utf8);
            
            try {
                await axios.delete(API_URL + `/api/template/${id}`, {
                    auth: {
                        username: user,
                        password: originalPass,
                    },
                    withCredentials: true,
                })
                setReset(!reset)
                messageSuccess(t("success_template_delete"))
            }
            catch (error) {
                messageError(t("error_template_delete"))
            }
        }
    }



    // Mensajes de error
    const [messageApi, contextHolder] = message.useMessage();
    const messageSuccess = (men) => {
        messageApi.open({
            type: 'success',
            content: men,
        });
    };



    // Mensajes de error
    const messageError = (men) => {
        messageApi.open({
            type: 'error',
            content: men,
        });
    };



    // Dar formato a la fecha
    const formatDate = (date) => {
        let momentDate = moment(date);
        let formattedDate = momentDate.format("DD/MM/YYYY HH:mm:ss");
        return formattedDate;
    }



    // Api senders
    const [senders, setSenders] = useState([]);
    const [loadingSenders, setLoadingSenders] = useState(true);
    const apiSenders = async () => {
        setLoadingSenders(true);
        const user = Cookies.get("user");
        const pass = Cookies.get("pass");
        
        if(!user || !pass) {
            setIsSession(false);
            setLoadingSenders(false);
            return;
        }
        else {
            const bytes  = CryptoJS.AES.decrypt(pass, "@murphyCookie");
            const originalPass = bytes.toString(CryptoJS.enc.Utf8);

            try {
                const res = await axios.get(API_URL + "/api/senders", {
                    auth: {
                        username: user,
                        password: originalPass,
                    },
                    withCredentials: true,
                })
                setSenders(res.data.data)
                setLoadingSenders(false);
            }
            catch (error) {
                setLoadingSenders(false);
                if(error.response.data.code === 1071) {
                    messageError(t("api_senders_error"))
                }
                else {
                    messageError(t("error_numbers"))
                }
            }
        }
    }
    useEffect(() => {
        apiSenders()
    }, [])


    // Para poner que tipo de template es
    const changeType = (value) => {
        setType(value)
        localStorage.setItem("type", value)
    }


    return (
        <div style={{padding: "0 80px"}}>
            <Flex justify="space-between" align="center">
                <Flex>
                    {localStorage.getItem("admin")==="true" &&
                        <Select
                            value={type}
                            style={{ width: 200 }}
                            options={[
                                { value: "1", label: t("list_template_agents") },
                                { value: "2", label: t("list_template_productions") },
                            ]}
                            onChange={(value) => changeType(value)}
                        />
                    }
                    
                    <Select
                        placeholder={t("select_list")}
                        loading={loadingSenders}
                        style={{ width: 200, marginLeft: "15px" }}
                        onChange={(value) => setSender(value)}
                        options={senders.map((item) => ({label: item.number, value: item.number}))}
                        allowClear
                    />
                </Flex>

                {localStorage.getItem("admin") === "true" && 
                    <Flex>
                        <Button onClick={() => navigate("/list/create")} type="ghost" icon={<PlusOutlined />} style={{marginTop: "10px", fontWeight: "600", color: "#5D3EAF", border: "1.8px solid #5D3EAF"}}>{type==="1" ? t("create_agent_template") : t("create_production_template")}</Button>
                        <Button onClick={() => navigate("/list/agent")} type="ghost" icon={<UnorderedListOutlined />} style={{marginTop: "10px", marginLeft: "15px", fontWeight: "600", color: "#5D3EAF", border: "1.8px solid #5D3EAF"}}>{t("list_agent_mayus")}</Button>
                    </Flex>
                }
            </Flex>

            <Flex style={{width: "100%", marginTop: "10px"}}>
                <Table
                    pagination={{
                        current: page,
                        pageSize: pageSize,
                        total: total,
                        position: ['bottomCenter'],
                        onChange: (page, pageSize) => {
                            setPage(page);
                            setPageSize(pageSize);
                        },
                    }}
                    scroll={{
                        y: '80vh',
                    }}
                    style={{width: "100%"}}
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                />
            </Flex>

            {contextHolder}
            {!isSession && <Session />}
        </div>
    )
}
