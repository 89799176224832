import React from 'react'
// Ant Design
import { Breadcrumb } from 'antd'
// React Router
import { Link } from 'react-router-dom'

export const BreadCrumb = ({t}) => {
    return (
        <Breadcrumb 
            style={{fontSize: "18px", padding: "10px"}}
            items={[
                {
                    title: "Home",
                },
                {
                    title: <Link to="/list">{t("list_templates")}</Link>,
                },
                {
                    title: t("sub_create"),
                },
            ]}
        />
    )
}