import React, { useEffect, useState } from 'react'
// Layout
import { Session } from '../../layout/Session'
// Ant Design
import { Flex, Typography, Input, Button, message, Select } from 'antd'
// React Router
import { useNavigate } from 'react-router-dom'
// CriptoJs
import CryptoJS from 'crypto-js'
// Cookie Js
import Cookies from 'js-cookie'
// Axios
import axios from 'axios'
// Config
import {API_URL} from '../../../config'

const { Title } = Typography;
const { TextArea } = Input;

export const Form = ({t}) => {

    const [isSession, setIsSession] = useState(true);
    const texto = localStorage.getItem("message");
    const [newText, setNewText] = useState("");
    const [sizeVar, setSizeVar] = useState([]);
    const [variables, setVariables] = useState([]);
    const navigate = useNavigate();



    // Para poner los valores de las variables en el texto
    useEffect(() => {
        const regex = /\{\{(\d+)\}\}/g;
        const matches = Array.from(texto.matchAll(regex), match => Number(match[1]));
        setSizeVar(matches);
    }, [texto])
    


    // Para poner los valores de los inputs en un array
    const onVariables = (index, event) => {
        const newNumbers = [...variables];
        newNumbers[index] = event.target.value;
        setVariables(newNumbers);
        onReplaceText(newNumbers);
    };



    // Para cambiar el texto del template
    const onReplaceText = (arrVar) => {
        let newText = texto;
        arrVar.forEach((item, index) => {
            newText = newText.replace(new RegExp(`\\{\\{${index + 1}\\}\\}`, "g"), item);
        });
        setNewText(newText);
    }



    // Enviar template API
    const templateId = localStorage.getItem("template");
    const [numberOne, setNumberOne] = useState("");
    const [numberTwo, setNumberTwo] = useState("");
    const [loading, setLoading] = useState(false);

    const sendTemplate = async () => {
        if(numberOne === "" || numberTwo === "" || templateId === "") {
            messageError(t("field_required"));
            return;
        }
        else if(numberTwo.length !== 10) {
            messageError(t("teen_digits"));
            return;
        }
        else {
            setLoading(true);
            const user = Cookies.get("user");
            const pass = Cookies.get("pass");
            
            if(!user || !pass) {
                setIsSession(false);
                setLoading(false);
                return;
            }
            else {
                const bytes  = CryptoJS.AES.decrypt(pass, "@murphyCookie");
                const originalPass = bytes.toString(CryptoJS.enc.Utf8);
                const obj = {};
                
                variables.forEach((item, index) => {
                    obj[(index + 1).toString()] = item;
                });

                try {
                    await axios.post(
                        API_URL + "/api/send/template", 
                        {
                            number_to: numberTwo,
                            number_from: numberOne,
                            template_id: templateId,
                            content_variables: obj,
                        }, 
                        {
                            auth: {
                                username: user,
                                password: originalPass,
                            },
                        }
                    )
                    localStorage.setItem("isSend", "true");
                    navigate("/list");
                    setLoading(false);
                }
                catch (error) {
                    setLoading(false);
                    messageError(t("error_send_template"));
                }
            }
        }
    }



    // Mensajes de error
    const [messageApi, contextHolder] = message.useMessage();
    const messageError = (men) => {
        messageApi.open({
            type: 'error',
            content: men,
        });
    };



    // Api senders
    const [senders, setSenders] = useState([]);
    const [loadingSenders, setLoadingSenders] = useState(true);
    const apiSenders = async () => {
        setLoadingSenders(true);
        const user = Cookies.get("user");
        const pass = Cookies.get("pass");
        
        if(!user || !pass) {
            setIsSession(false);
            setLoadingSenders(false);
            return;
        }
        else {
            const bytes  = CryptoJS.AES.decrypt(pass, "@murphyCookie");
            const originalPass = bytes.toString(CryptoJS.enc.Utf8);
            
            try {
                const res = await axios.get(API_URL + "/api/senders", {
                    auth: {
                        username: user,
                        password: originalPass,
                    },
                    withCredentials: true,
                })
                setSenders(res.data.data)
                setLoadingSenders(false);
            }
            catch (error) {
                messageError(t("error_numbers"));
                setLoadingSenders(false);
            }
        }
    }
    useEffect(() => {
        apiSenders()
    }, [])

    

    return (
        <Flex vertical style={{marginTop: "10px", padding: "0 80px"}}>
            <Title level={3} style={{color: "#F1D030", textAlign: "center", textTransform: "uppercase"}}>{t("send_template")}</Title>
        
            <Flex vertical>
                <Title level={4} style={{color: "#5D3EAF"}}>{t("create_number")}</Title>
                <Select
                    placeholder={t("select_list")}
                    loading={loadingSenders}
                    size="large"
                    options={senders.map((item) => ({label: item.number, value: item.number}))}
                    onChange={(value) => setNumberOne(value)}
                />
            </Flex>
        
            <Flex vertical style={{marginTop: "15px"}}>
                <Title level={4} style={{color: "#5D3EAF"}}>Template ID</Title>
                <Input placeholder="Template ID" size="large" value={localStorage.getItem("template")} />
            </Flex>

            <Flex vertical style={{marginTop: "15px"}}>
                <Title level={4} style={{color: "#5D3EAF"}}>{t("create_body")}</Title>
                <TextArea placeholder={t("create_body")} autoSize value={newText==="" ? texto : newText} />
            </Flex>

            <Flex gap={1} wrap justify="space-between">
                {sizeVar?.map((item, index) => (
                    <Flex key={item} vertical style={{marginTop: "15px", width: "48%"}}>
                        <Title level={4} style={{color: "#5D3EAF"}}>Variable {item}</Title>
                        <Input onChange={(e) => onVariables(index, e)} placeholder={"Variable " + item} size="large" />
                    </Flex>
                ))}
            </Flex>

            <Flex vertical style={{marginTop: "15px"}}>
                <Title level={4} style={{color: "#5D3EAF"}}>{t("send_client")}</Title>
                <Input placeholder="5555555555" size="large" onChange={(e) => setNumberTwo(e.target.value)} />
            </Flex>

            <Flex justify="center" style={{margin: "20px 0"}}>
                <Button onClick={sendTemplate} loading={loading} type="ghost" style={{fontWeight: "600", color: "#5D3EAF", border: "1.8px solid #5D3EAF"}}>{t("btn_send_send")}</Button>
                <Button onClick={() => navigate("/list")} type="ghost" style={{fontWeight: "600", color: "gray", border: "1.8px solid gray", marginLeft: "20px"}}>{t("btn_cancel")}</Button>
            </Flex>

            {contextHolder}
            {!isSession && <Session />}
        </Flex>
    )
}
