import React, { useEffect, useState } from 'react'
// Layout
import { Navbar } from '../layout/Navbar'
import { Session } from '../layout/Session'
// i18
import { useTranslation } from 'react-i18next'
// Fragments
import { BreadCrumb } from '../fragments/List/BreadCrumb'
import { Searching } from '../fragments/List/Searching'
import { Tabla } from '../fragments/List/Tabla'
// Ant Design
import { Flex, message } from 'antd'
// CriptoJs
import CryptoJS from 'crypto-js'
// Cookie Js
import Cookies from 'js-cookie'
// Axios
import axios from 'axios'
// Config
import {API_URL} from '../../config'

export const ListTemplates = () => {
    const { t } = useTranslation(["translation"]);


    // Numero de tabla
    const [sender, setSender] = useState("");
    // Reset Tabla
    const [reset, setReset] = useState(false);
    // Buscador
    const [search, setSearch] = useState("");
    // Tipo de tabla
    const [type, setType] = useState(localStorage.getItem("type") || "1");


    // Mensajes de error
    const [messageApi, contextHolder] = message.useMessage();
    const errorApi = (men) => {
        messageApi.open({
            type: 'error',
            content: men,
        });
    };
    const messageSuccess = (men) => {
        messageApi.open({
            type: 'success',
            content: men,
        });
    };



    // Si se envio template o creo
    const isSend = localStorage.getItem("isSend");
    const isTemplate = localStorage.getItem("isTemplate");
    useEffect(() => {
        if(isSend === "true") {
            messageSuccess(t("template_send"));
            localStorage.setItem("isSend", "false");
        }
        if(isTemplate === "true") {
            messageSuccess(t("template_create"));
            localStorage.setItem("isTemplate", "false");
        }
    }, [])



    // Para tabla
    const [total, setTotal] = useState(1);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    // Api Lista de Templates
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    const apiTemplates = async () => {
        setLoading(true)
        
        var pageNew = 0
        if(page <= 0) {
            pageNew = 0
        }
        else {
            pageNew = (page*pageSize)-pageSize
        }

        const isAdmin = localStorage.getItem("admin");
        let isType;
        if(isAdmin === "false") {
            isType = false
        }
        else {
            if(type === "1") {
                isType = false
            }
            else {
                isType = true
            }
        }
        
        var url = ""
        if(search !== "" && sender !== "") {
            url = API_URL + `/api/template?production_template=${isType}&number=${sender}&skip=${pageNew}&limit=${pageSize}&searchParam=${search}`;
        }
        else if(search !== "" && sender === "") {
            url = API_URL + `/api/template?production_template=${isType}&skip=${pageNew}&limit=${pageSize}&searchParam=${search}`;
        }
        else if(sender === "") {
            url = API_URL + `/api/template?production_template=${isType}&skip=${pageNew}&limit=${pageSize}`;
        }
        else if(sender !== "") {
            url = API_URL + `/api/template?production_template=${isType}&number=${sender}`
        }
        
        const user = Cookies.get("user");
        const pass = Cookies.get("pass");

        if(user) {
            const bytes  = CryptoJS.AES.decrypt(pass, "@murphyCookie");
            const originalPass = bytes.toString(CryptoJS.enc.Utf8);
    
            try {         
                const res = await axios.get(url, {
                    auth: {
                        username: user,
                        password: originalPass,
                    },
                    withCredentials: true,
                })
                setTemplates(res.data.data)
                setTotal(res.data.num_items)
                setLoading(false)
            }
            catch (error) {
                setLoading(false)
                if(error.response.data.code === 1070) {
                    errorApi(t("api_template_error"))
                }
                else {
                    errorApi(t("error_templates"))
                }
            }
        }
    }
    useEffect(() => {
        apiTemplates()
    }, [sender, reset, page, pageSize, search, type])



    return (
        <section>
            <Navbar />

            <Flex style={{padding: "10px"}}>
                <BreadCrumb t={t} />
                <Searching t={t} setSearch={setSearch} />
            </Flex>

            <Tabla 
                t={t} 
                setSender={setSender} 
                templates={templates} 
                loading={loading}
                reset={reset}
                setReset={setReset}
                total={total}
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                type={type}
                setType={setType}
            />

            {contextHolder}
            <Session />
        </section>
    )
}
