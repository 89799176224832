import React, { useEffect, useState } from 'react'
// Layout
import { Session } from '../../layout/Session'
// Ant Design
import { Flex, Button, Table, Tooltip, Modal, Input, Popconfirm, message } from 'antd'
// Icons
import { DeleteOutlined, UserAddOutlined } from '@ant-design/icons'
// Moment Js
import moment from 'moment'
// CriptoJs
import CryptoJS from 'crypto-js'
// Cookie Js
import Cookies from 'js-cookie'
// Axios
import axios from 'axios'
// Config
import {API_URL} from '../../../config'

export const Tabla = ({t, agentes, loading, reset, setReset, total, page, setPage, pageSize, setPageSize}) => {
    // Variable para validar la sesion
    const [isSession, setIsSession] = useState(true);


    // Columnas de la tabla
    const columns = [
        {
            title: <p style={{fontWeight: "700", fontSize: "15px"}}>{t("table_nameonly")}</p>,
            dataIndex: 'nombre',
        },
        {
            title: <p style={{fontWeight: "700", fontSize: "15px"}}>{t("table_email")}</p>,
            dataIndex: 'correo',
        },
        {
            title: <p style={{fontWeight: "700", fontSize: "15px"}}>{t("table_createdAt")}</p>,
            dataIndex: 'creado',
        },
        {
            title: '',
            dataIndex: 'eliminar',
            width: 80,
        },
    ];



    // Para data de tabla
    const [data, setData] = useState([]);
    useEffect(() => {
        const arr = [];
        agentes.map((item) => (
            arr.push({
                key: item._id,
                nombre: item.full_name,
                correo: item.email,
                creado: formatDate(item.createdAt),
                eliminar: (
                    <Tooltip title={t("btn_delete")} color="#FF0000">
                        <Popconfirm
                            title={t("delete_agent")}
                            description={t("answer_delete_agent")}
                            onConfirm={() => deleteUser(item._id)}
                            okText={t("text_yes")}
                            cancelText="No"
                            zIndex={999999}
                        >
                            <Button type="ghost" shape="circle" style={{color: "#FF0000", border: "1px solid #FF0000"}} icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </Tooltip>
                )
            })
        ))
        setData(arr);
    }, [agentes])



    // Eliminar usuario
    const deleteUser = async (id) => {
        const user = Cookies.get("user");
        const pass = Cookies.get("pass");
        
        if(!user || !pass) {
            setIsSession(false);
            return;
        }
        else {
            const bytes  = CryptoJS.AES.decrypt(pass, "@murphyCookie");
            const originalPass = bytes.toString(CryptoJS.enc.Utf8);

            try {
                await axios.delete(API_URL + `/api/users/${id}`, {
                    auth: {
                        username: user,
                        password: originalPass,
                    },
                    withCredentials: true,
                })
                setReset(!reset)
                messageSuccess(t("success_delete"));
            }
            catch (error) {
                messageError(t("error_delete"));
            }
        }
    }



    // Dar formato a la fecha
    const formatDate = (date) => {
        let momentDate = moment(date);
        let formattedDate = momentDate.format("DD/MM/YYYY HH:mm:ss");
        return formattedDate;
    }



    // Mensajes de error
    const [messageApi, contextHolder] = message.useMessage();
    const messageError = (men) => {
        messageApi.open({
            type: 'error',
            content: men,
        });
    };



    // Mensajes de Success
    const messageSuccess = (men) => {
        messageApi.open({
            type: 'success',
            content: men,
        });
    };



    // Para modal de crear agente
    function ModalComponent() {
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [name, setName] = useState("");
        const [email, setEmail] = useState("");
        const [password, setPassword] = useState("");
        const [confirmPassword, setConfirmPassword] = useState("");
        const [loading, setLoading] = useState(false);

        const createUser = async () => {
            if (name === "" || email === "" || password === "" || confirmPassword === "") {
                messageError(t("field_required"));
                return;
            }
            else if (/\d/.test(name)) {
                messageError(t("no_number"));
                return;
            }
            else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
                messageError(t("format_email"));
                return;
            }
            else if (password !== confirmPassword) {
                messageError(t("password_same"));
                return;
            }
            else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).+$/.test(password)) {
                messageError(t("required_pass"));
                return;
            }
            else {
                setLoading(true);
                const nameClean = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

                const user = Cookies.get("user");
                const pass = Cookies.get("pass");
                
                if(!user || !pass) {
                    setIsSession(false);
                    setLoading(false);
                    return;
                }
                else {
                    const bytes  = CryptoJS.AES.decrypt(pass, "@murphyCookie");
                    const originalPass = bytes.toString(CryptoJS.enc.Utf8);

                    try {
                        const res = await axios.post(
                            API_URL + "/api/users", 
                            {
                                full_name: nameClean,
                                email: email,
                                password: password,
                                is_admin: false,
                            }, 
                            {
                                auth: {
                                    username: user,
                                    password: originalPass,
                                },
                            }
                        )
                        if(res.status === 201) {
                            setReset(!reset);
                            setIsModalOpen(false);
                            messageSuccess(t("success_user"));
                            setLoading(false);
                        }
                    }
                    catch (error) {
                        setLoading(false);
                        if(error.response.data.code===1065) {
                            messageError(t("api_agent_error"));
                        }
                        else {
                            messageError(t("error_create"));
                        }
                    }
                }
            }
        }

        return (
            <>
                <Button onClick={() => setIsModalOpen(true)} type="ghost" icon={<UserAddOutlined />} style={{marginTop: "10px", fontWeight: "600", color: "#5D3EAF", border: "1.8px solid #5D3EAF"}}>{t("btn_add")}</Button>  
                <Modal title={t("btn_add")} okText={t("btn_add_modal")} cancelText={t("btn_cancel")} open={isModalOpen} onOk={createUser} onCancel={() => setIsModalOpen(false)} confirmLoading={loading}>
                    <Flex vertical>
                        <Input placeholder={t("names")} size="large" onChange={(e) => setName(e.target.value)} />
                        <Input placeholder={t("table_email")} size="large" style={{marginTop: "15px"}} onChange={(e) => setEmail(e.target.value)} />
                        <Input.Password placeholder={t("input_password")} size="large" style={{marginTop: "15px"}} onChange={(e) => setPassword(e.target.value)} />
                        <Input.Password placeholder={t("confirm_password")} size="large" style={{marginTop: "15px"}} onChange={(e) => setConfirmPassword(e.target.value)} />
                    </Flex>
                </Modal>
            </>
        )
    }


    
    return (
        <div style={{padding: "0 80px"}}>
            <Flex justify="flex-end">
                <ModalComponent />
            </Flex>

            <Flex style={{width: "100%", marginTop: "10px"}}>
                <Table
                    pagination={{
                        current: page,
                        pageSize: pageSize,
                        total: total,
                        position: ['bottomCenter'],
                        onChange: (page, pageSize) => {
                            setPage(page);
                            setPageSize(pageSize);
                        },
                    }}
                    scroll={{
                        y: '80vh',
                    }}
                    style={{width: "100%"}}
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                />
            </Flex>

            {contextHolder}
            {!isSession && <Session />}
        </div>
    )
}
