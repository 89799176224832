import React, { useEffect, useState } from 'react'
// Layout
import { Session } from '../../layout/Session'
// Ant Design
import { Flex, Typography, Input, Select, Button, message } from 'antd'
// React Router
import { useNavigate } from 'react-router-dom'
// CriptoJs
import CryptoJS from 'crypto-js'
// Cookie Js
import Cookies from 'js-cookie'
// Axios
import axios from 'axios'
// Config
import {API_URL} from '../../../config'

const { Title } = Typography;
const { TextArea } = Input;

export const Form = ({t}) => {

    const navigate = useNavigate();
    const [isSession, setIsSession] = useState(true);

    const [name, setName] = useState("");
    const [lenguague, setLenguague] = useState("Spanish(MEX)");
    const [number, setNumber] = useState("");
    const [idTemplate, setIdTemplate] = useState("");
    const [body, setBody] = useState("");
    const [loading, setLoading] = useState(false);

    const createTemplate = async() => {
        if(name === "" || lenguague === "" || number === "" || idTemplate === "" || body === "") {
            messageError("Todos los campos son requeridos");
            return;
        }
        else {
            setLoading(true);
            const nameClean = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

            const user = Cookies.get("user");
            const pass = Cookies.get("pass");
            
            if(!user || !pass) {
                setIsSession(false);
                setLoading(false);
                return;
            }
            else {
                const bytes  = CryptoJS.AES.decrypt(pass, "@murphyCookie");
                const originalPass = bytes.toString(CryptoJS.enc.Utf8);

                var type;
                if(localStorage.getItem("type") === "1") {
                    type = false;
                }
                else {
                    type = true;
                }

                try {
                    const res = await axios.post(
                        API_URL + "/api/template", 
                        {
                            id_template: idTemplate,
                            name: nameClean,
                            language: lenguague,
                            number: number,
                            message: body,
                            production_template: type,
                        }, 
                        {
                            auth: {
                                username: user,
                                password: originalPass,
                            },
                        }
                    );
                    if(res.status === 201) {
                        localStorage.setItem("isTemplate", "true")
                        setLoading(false);
                        navigate("/list");
                    }
                }
                catch (error) {
                    messageError(t("error_template"));
                    setLoading(false);
                }
            }
        }
    }

    // Mensajes de error
    const [messageApi, contextHolder] = message.useMessage();
    const messageError = (men) => {
        messageApi.open({
            type: 'error',
            content: men,
        });
    };



    // Api senders
    const [senders, setSenders] = useState([]);
    const [loadingSenders, setLoadingSenders] = useState(true);
    const apiSenders = async () => {
        setLoadingSenders(true);
        const user = Cookies.get("user");
        const pass = Cookies.get("pass");

        if(user) {
            const bytes  = CryptoJS.AES.decrypt(pass, "@murphyCookie");
            const originalPass = bytes.toString(CryptoJS.enc.Utf8);
    
            try {
                const res = await axios.get(API_URL + "/api/senders", {
                    auth: {
                        username: user,
                        password: originalPass,
                    },
                    withCredentials: true,
                })
                setSenders(res.data.data)
                setLoadingSenders(false);
            }
            catch (error) {
                setLoadingSenders(false);
                if(error.response.data.code === 1071) {
                    messageError(t("api_senders_error"))
                }
                else {
                    messageError(t("error_numbers"))
                }
            }
        }
    }
    useEffect(() => {
        apiSenders()
    }, [])

    return (
        <Flex vertical style={{marginTop: "10px", padding: "0 80px"}}>
            <Title level={3} style={{color: "#F1D030", textAlign: "center"}}>{localStorage.getItem("type")==="1" ? t("create_agent_template") : t("create_production_template")}</Title>
        
            <Flex vertical>
                <Title level={4} style={{color: "#5D3EAF"}}>{t("create_name")}</Title>
                <Input placeholder={t("create_name")} size="large" onChange={(e) => setName(e.target.value)} />
            </Flex>

            <Flex vertical style={{marginTop: "15px"}}>
                <Title level={4} style={{color: "#5D3EAF"}}>{t("create_lenguague")}</Title>
                <Select
                    placeholder={t("select_lenguage")}
                    size="large"
                    options={[
                        { value: 'Spanish (MEX)', label: 'Spanish (MEX)' },
                        { value: 'Spanish (ESP)', label: 'Spanish (ESP)' },
                        { value: 'English (EU)', label: 'English (EU)' },
                        { value: 'English (ENG)', label: 'English (ENG)' },
                    ]}
                    onChange={(value) => setLenguague(value)}
                />
            </Flex>

            <Flex vertical style={{marginTop: "15px"}}>
                <Title level={4} style={{color: "#5D3EAF"}}>{t("create_number")}</Title>
                <Select
                    placeholder={t("select_list")}
                    loading={loadingSenders}
                    size="large"
                    options={senders.map((item) => ({label: item.number, value: item.number}))}
                    onChange={(value) => setNumber(value)}
                />
            </Flex>

            <Flex vertical style={{marginTop: "15px"}}>
                <Title level={4} style={{color: "#5D3EAF"}}>ID Template</Title>
                <Input placeholder="00000000000000" size="large" onChange={(e) => setIdTemplate(e.target.value)} />
            </Flex>

            <Flex vertical style={{marginTop: "15px"}}>
                <Title level={4} style={{color: "#5D3EAF"}}>{t("create_body")}</Title>
                <TextArea placeholder={t("create_body")} autoSize onChange={(e) => setBody(e.target.value)} />
            </Flex>

            <Flex justify="center" style={{margin: "20px 0"}}>
                <Button onClick={createTemplate} loading={loading} type="ghost" style={{fontWeight: "600", color: "#5D3EAF", border: "1.8px solid #5D3EAF"}}>{t("btn_create")}</Button>
                <Button onClick={() => navigate("/list")} type="ghost" style={{fontWeight: "600", color: "gray", border: "1.8px solid gray", marginLeft: "20px"}}>{t("btn_cancel")}</Button>
            </Flex>

            {contextHolder}
            {!isSession && <Session />}
        </Flex>
    )
}
