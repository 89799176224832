import React, { useEffect, useState } from 'react'
// i18
import { useTranslation } from 'react-i18next'
// Layout
import { Navbar } from '../layout/Navbar'
import { Back } from '../layout/Back'
import { Session } from '../layout/Session'
// Fragments
import { BreadCrumb } from '../fragments/Agent/BreadCrumb'
import { Searching } from '../fragments/Agent/Searching'
import { Tabla } from '../fragments/Agent/Tabla'
// Ant Design
import { Flex, message } from 'antd'
// CriptoJs
import CryptoJS from 'crypto-js'
// Cookie Js
import Cookies from 'js-cookie';
// Axios
import axios from 'axios'
// Config
import {API_URL} from '../../config'

export const ListAgent = () => {
    const { t } = useTranslation(["translation"]);



    // Reset Tabla
    const [reset, setReset] = useState(false);
    // Buscador
    const [search, setSearch] = useState("");



    // Mensajes de error
    const [messageApi, contextHolder] = message.useMessage();
    const errorApi = (men) => {
        messageApi.open({
            type: 'error',
            content: men,
        });
    };



    // Para tabla
    const [total, setTotal] = useState(1);
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    // Api Agentes
    const [agentes, setAgentes] = useState([]);
    const [loading, setLoading] = useState(false);
    const apiAgentes = async() => {
        setLoading(true)
        const user = Cookies.get("user");
        const pass = Cookies.get("pass");

        if(user) {
            const bytes  = CryptoJS.AES.decrypt(pass, "@murphyCookie");
            const originalPass = bytes.toString(CryptoJS.enc.Utf8);
    
            var pageNew = 0
            if(page <= 0) {
                pageNew = 0
            }
            else {
                pageNew = (page*pageSize)-pageSize
            }
    
            var url = "";
            if(search !== "") {
                url = `/api/users?skip=${pageNew}&limit=${pageSize}&searchParam=${search}`
            }
            else {
                url = `/api/users?skip=${pageNew}&limit=${pageSize}`
            }
    
            try {
                const res = await axios.get(API_URL + url, {
                    auth: {
                        username: user,
                        password: originalPass,
                    },
                    withCredentials: true,
                });
                setAgentes(res.data.data);
                setTotal(res.data.num_items);
                setLoading(false);
            }
            catch (error) {
                setLoading(false);
                if(error.response.data.code === 1072) {
                    errorApi(t("api_users_error"));
                }   
                else {
                    errorApi(t("error_user"));
                }
            }
        }
    }
    useEffect(() => {
        apiAgentes();
    }, [reset, page, pageSize, search])
    

    
    return (
        <section>
            <Navbar />

            <Flex style={{padding: "10px"}}>
                <BreadCrumb t={t} />
                <Searching t={t} setSearch={setSearch} />
            </Flex>

            <Tabla 
                t={t} 
                agentes={agentes}
                loading={loading}
                reset={reset}
                setReset={setReset}
                total={total}
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
            />

            <Back />

            {contextHolder}
            <Session />
        </section>
    )
}
