import React from 'react'
// React Router
import { useNavigate } from 'react-router-dom'
// i18
import { useTranslation } from 'react-i18next'
// Ant Design
import { Button, Tooltip } from 'antd'
// Icons
import { LeftOutlined } from '@ant-design/icons'

export const Back = () => {
    
    const navigate = useNavigate()
    const { t } = useTranslation(["translation"]);

    return (
        <div style={{position: "fixed", bottom: "10px", left: "10px", zIndex: 100}}>
            <Tooltip title={t("btn_back")} placement="top" color="#5D3EAF">
                <Button onClick={() => navigate("/list")} type="ghost" size="large" shape="circle" style={{color: "#5D3EAF", border: "1.8px solid #5D3EAF"}} icon={<LeftOutlined />} />
            </Tooltip>
        </div>
    )
}
