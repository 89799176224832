import React, { useEffect } from 'react'
// AntDesign
import { Modal } from 'antd'
// React Router
import { useNavigate } from 'react-router-dom'
// Cookie Js
import Cookies from 'js-cookie'
// i18
import { useTranslation } from 'react-i18next'

export const Session = () => {

    // Para redireccionar
    const navigate = useNavigate()

    // Para el idioma
    const { t } = useTranslation(["translation"]);

    // Modal seción expirada
    const error = () => {
        Modal.error({
            title: t("session_expired"),
            content: t("session_expired_text"),
            onOk: () => {
                navigate("/")
            },
        });
    };

    // Validacion si hay cookies
    useEffect(() => {
        const user = Cookies.get("user");
        const pass = Cookies.get("pass");
        if(!user || !pass) {
            error()
        }
    }, [])

    return (<></>)
}
