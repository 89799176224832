import React from 'react'
// Ant Design
import { Breadcrumb, Flex } from 'antd'

export const BreadCrumb = ({t}) => {
    return (
        <Flex style={{width: "50%"}}>
            <Breadcrumb 
                style={{fontSize: "18px"}}
                items={[
                    {
                        title: "Home",
                    },
                    {
                        title: t("list_templates"),
                    },
                ]}
            />
        </Flex>
    )
}
