import { useEffect } from 'react';
// React Router
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// i18
import { useTranslation } from 'react-i18next'
// Pages
import { Login } from './components/pages/Login';
import { ListTemplates } from './components/pages/ListTemplates';
import { CreateTemplate } from './components/pages/CreateTemplate';
import { SendTemplate } from './components/pages/SendTemplate';
import { ListAgent } from './components/pages/ListAgent';

function App() {

    // Para el idioma
    const { i18n } = useTranslation(["translation"]);
    useEffect(() => {
        if(localStorage.getItem("lang")==="true") {
            i18n.changeLanguage("es");
        }
        else {
            i18n.changeLanguage("en");
        }
    }, [])

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/list" element={<ListTemplates />} />
                <Route path="/list/create" element={<CreateTemplate />} />
                <Route path="/list/send" element={<SendTemplate />} />
                <Route path="/list/agent" element={<ListAgent />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;