import React from 'react'
// React Router
import { useNavigate } from 'react-router-dom'
// i18
import { useTranslation } from 'react-i18next'
// Ant Design
import { Flex, Image, Button, Switch } from 'antd'
// Icons
import { LogoutOutlined } from '@ant-design/icons'

export const Navbar = () => {

    const navigate = useNavigate()

    // Para el idioma
    const { t, i18n } = useTranslation(["translation"]);
    const handleChange = (value) => {
        if(value) {
            i18n.changeLanguage("es"); 
            localStorage.setItem("lang", "true");
        }
        else {
            i18n.changeLanguage("en");
            localStorage.setItem("lang", "false");
        }
    };

    return (
        <nav style={{width: "100%", zIndex: "10"}}>
            <Flex justify="space-between" align="center" style={{position: "relative", overflow: "hidden", backgroundColor: "#0F0E24", height: "80px", padding: "0 20px"}}>
                <Image style={{minWidth: "100px", maxWidth: "300px", maxHeight: "80px"}} src="https://www.uvp.mx/assets/img/logo_uvp.png" />

                <Flex gap={12} align="center">
                    <Switch onChange={handleChange} className="custom-switch" checkedChildren="ES" unCheckedChildren="EN" defaultValue={localStorage.getItem("lang")==="true" ? true : false} />
                    <Button onClick={() => navigate("/login")} type="ghost" size="large" icon={<LogoutOutlined />} style={{color: "#fff", border: "1.5px solid #fff", padding: "2px 10px", fontWeight: "400"}}>{t("logout")}</Button>
                </Flex>
            </Flex>

            <div style={{background: "linear-gradient(90deg, rgba(241,208,48,1) 0%, rgba(132,32,118,1) 100%)", height: "3px"}}></div>
        </nav>
    )
}
