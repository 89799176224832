import React from 'react'
// Ant Design
import { Flex, Input } from 'antd'
const { Search } = Input;

export const Searching = ({t, setSearch}) => {

    return (
        <Flex style={{width: "50%", zIndex: "10"}} justify="flex-end">
            <Search onSearch={(value) => setSearch(value)} placeholder={t("input_list")} allowClear size="large" style={{ width: "70%" }} />
        </Flex>
    )
}
