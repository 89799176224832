import React from 'react'
// i18
import { useTranslation } from 'react-i18next'
// Layout
import { Navbar } from '../layout/Navbar'
import { Back } from '../layout/Back'
import { Session } from '../layout/Session'
// Fragments
import { BreadCrumb } from '../fragments/Create/BreadCrumb'
import { Form } from '../fragments/Create/Form'

export const CreateTemplate = () => {
    const { t } = useTranslation(["translation"]);

    return (
        <section>
            <Navbar />
            <BreadCrumb t={t} />
            <Form t={t} />
            <Back />
            <Session />
        </section>
    )
}
